<template>
	<div v-if="!previewDocs" class="ui-file wrapper" :class="mode">
		<div v-if="tip" class="ui-file__tip form-block__tip">
			<UiTip
				:message="tip.message"
			/>
		</div>
		<div 
			class="upload-block" 
			:class="uploadClasses"
			@dragover="dragover"
			@dragleave="dragleave"
			@drop="drop"
		>
			<div class="ui-file__header">
				<span class="upload-block-title">{{ title }}</span>
			</div>
			
			<span v-if="showSubtitle" class="upload-block-subtitle">{{ subtitle }}</span>

			<span v-if="isDragging" class="upload-block-subtitle">Отпустите для загрузки</span>

			<div v-if="mode === 'edit'">
				<input
					ref="fileInput"
					class="custom-file-input"
					type="file"
					:multiple="multiple"
					:accept="effectiveAccept"
					@change="onInput"
				>
				<UiButton
					:disabled="disabled"
					prepend-icon="icons-upload"
					type="secondary"
					label="Загрузить файл"
					:pending="pendingButton"
					@click.prevent="fileCtrlClick"
				>
					Загрузить файл
				</UiButton>
			</div>

			<span
				v-if="mode==='edit'"
				class="input-tips"
			>
				{{ tips }}
			</span>

			<ul v-show="hasFiles" class="preview-list">
				<template v-for="(fileObj, fileIdx) in fileArr">
					<img
						v-if="preview"
						:key="`img#${fileIdx}`"
						class="img-preview" 
						:src="fileObj.link"
						:height="height" 
						:width="width"
						alt="Предпросмотр изображения"
						title="Предпросмотр изображения"
					>
					
					<!-- Linter ругается ошибочно. Ошибки нет. -->
					<li :key="`li#${fileIdx}`">
						<a
							v-show="fileObj.link"
							href="#"
							class="preview-list__file-name"
							@click.prevent="downloadFile(fileObj)"
						>
							{{ fileObj.file ? fileObj.file.name : fileObj.name }}
						</a>

						<span
							v-show="!fileObj.link"
							class="preview-list__file-name"
						>
							{{ fileObj.file ? fileObj.file.name : fileObj.name }}
						</span>

						<div
							v-if="mode==='edit'"
							class="remove-file"
							@click="onRemove(fileIdx)"
						/>
					</li>
				</template>
			</ul>

			<div v-if="mode==='view'" v-show="!hasFiles">
				<div v-if="multiple">
					Файлы не загружены
				</div>
				<div v-else>
					Файл не загружен
				</div>
			</div>

			<span
				v-if="mode==='edit'"
				v-show="error"
				class="input-caption input-caption__error"
			>
				{{ error }}
			</span>
		</div>

	</div>
	<div v-else-if="fileArr.length" class="preview-docs">
		<h3 class="preview-docs__title">{{ title }}</h3>
		<ul class="preview-docs__list">
			<li v-for="(doc, fileIdx) in fileArr" :key="doc.documentId" class="preview-docs__item"> 
				<a v-if="doc.legalDoc" target="_blank" class="preview-docs__item-link" :href="documentViewRoute(doc)">
					<slot name="docName" v-bind="{ doc }">
						{{ doc.file.name }}
					</slot>
				</a>
				<a v-else class="preview-docs__item-link" href="#" @click.prevent="downloadFile(doc)">
					<slot name="docName" v-bind="{ doc }">
						{{ doc.file.name }}
					</slot>
				</a>
				<div
					v-if="mode === 'edit'"
					class="preview-docs__remove-file"
					@click="onRemove(fileIdx)"
				/>
			</li>
		</ul>
		<input
			ref="fileInput"
			class="preview-docs__file-input"
			type="file"
			:multiple="multiple"
			:accept="effectiveAccept"
			@change="onInput"
		>
		<UiButton
			v-if="mode === 'edit'"
			label="Добавить документ"
			type="secondary"
			prependIcon="add-icon"
			size="xs"
			class="preview-docs__add-btn"
			:pending="pendingButton"
			@click="fileCtrlClick"
		/>
	</div>
</template>

<script>
import { DOCUMENT_SIGN_WATCH_PAGE } from '@/router/routes/names';
import { randomUuid } from '@utils/common';
import { ERROR, SUCCESS } from '@/configs/ui';
import { saveArrayBufferToFile } from '@/utils/common';
import { getFilePublic } from '@/services/files';
import { downloadWithStamp } from "@services/documents";

export default {
	name: 'UiFile',
	/**
	 * Модель - объект или массив объектов (FileSpecs) со свойствами:
	 * - file: File - файл, загруженный в input, заполняется при выборе файла в диалоге, очищается при удалении;
	 * - fileId: number | string - id загруженного на на бэк файла, заполняется при разрешении upload-handler, удаляется при удалении;
	 * - fileUrl: string - url файла для просмотра, заполняется при разрешении upload-handler, удаляется при удалении;
	 * Также в объект добавляются свойства mixData.
	 * Массив используется при multiple = true, иначе объект.
	 */
	model: {
		prop: 'files',
		event: 'changeValue'
	},

	props: {
		/**
		 * Входное свойство модели (описано выше)
		 */
		files: {
			type: [ Object, Array ],
			required: false,
		},
		/**
		 * Заголовок блока указания файла
		 */
		title: {
			type: String,
			required: false
		},
		/**
		 * Подзаголовок блока указания файла
		 */
		subtitle: {
			type: String,
			required: false
		},
		/**
		 * Подсказка (отображается под кнопкой загрузки)
		 */
		tips: {
			type: String,
			required: false
		},
		/**
		 * Свойство для управления доступными для выбора типами файлов. См. <input accept="" > 
		 */
		accept: {
			type: String,
			required: false
		},
		/**
		 * Массив предопределенных "семейств" типов файлов.
		 * Строка для единичного значения, массив строк для набора значений.
		 * Порядок не важен. Регистр не важен.
		 * Поддерживаются значения: text, image, video, audio, pdf, docx, xlsx, zip, ...
		 * Игнорируется при непустом accept.
		 */
		acceptTypes: {
			type: [Array, String],
			required: false
		},
		/**
		 * Позволяет выбирать не один, а сразу несколько файлов.
		 * В этом случае в модели может быть массив больше одного элемента.
		 * При !multiple - в модели массив не более 1 элемента.
		 */
		multiple: {
			type: Boolean,
			default: false
		},
		disabled:{
			type: Boolean,
			default: false
		},
		/**
		 * Сообщение об ошибке. (отображается под селектом, заменяет hint)
		 */
		error: {
			type: String,
			default: '',
		},

		/**
		 * Режим работы контрола
		 * Допустимые значения:
		 *   edit - обычный режим редактирования
		 *   view - режим просмотра
		 */
		mode: {
			type: String,
			default: "edit",
			validator: val => ["edit", "view"].includes(val),
		},
		/**
		 * Режим предпросмотра
		 * Выводится миниатюра изображения. Могут использоваться width и height.
		 */
		preview: {
			type: Boolean,
			default: false,
		},
		/**
		 * Режим просмотра документа в драфт-проектах и проектах
		 * Если стоит флаг edit, их можно удалять/добавлять
		 */
		previewDocs: {
			type: Boolean,
			default: false,
		},
		/**
		 * Ширина и высота миниатюры при предпросмотре (preview)
		 */
		width: String,
		height: String,

		/**
		 * Свойства, подмешиваемые в модель
		 */
		mixData: {
			type: Object,
			default: () => ({}),
		},

		/**
		 * Функция-загрузчик выбранного файла.
		 * Должна иметь интерфейс: <code>function(file: File): Promise<FileSpecs></code>
		 * Вызавается после выбора файла и асинхронно выполняет загрузку,
		 * после завершения разрешается в FileSpecs (см. выше).
		 */
		uploadHandler: {
			type: Function,
			required: false
		},
		/**
		 * Функция удаления файла.
		 * Должна иметь интерфейс: <code>function(file: FileSpecs): Promise<FileSpecs></code>
		 * Вызавается после удаления файла с его FileSpecs и асинхронно выполняет удаление на бэке,
		 * после завершения разрешается в FileSpecs (удаленный).
		 */
		deleteHandler: {
			type: Function,
			required: false
		},

		/**
		 * Функция преобразования объектов, переданных во входное свойство в FileSpecs.
		 * Позволяет изменить работу со структурами модели, отличными от FileSpecs.
		 * Должна иметь интерфейс: <code>function(value: {*}): FileSpecs</code>
		 */
		propMapper: {
			type: Function,
			required: false,
		},
		/**
		 * Функция преобразования объектов FileSpecs в объекты модели, передаваемые в событие v-model.
		 * Позволяет изменить работать со структурами модели, отличными от FileSpecs.
		 * Сначала подмешивается mixData, а затем вызывается маппер.
		 * Должна иметь интерфейс: <code>function(value: FileSpecs): {*}</code>
		 */
		eventMapper: {
			type: Function,
			required: false,
		},
		/**
		 * Объект подсказки
		 */
		tip: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			fileArr: [],
			pendingButton: false,
			isDragging: false
		}
	},

	computed: {
		fileCtrl() {
			return this.$refs.fileInput;
		},

		hasFiles() {
			return this.fileArr && Array.isArray(this.fileArr) && this.fileArr.length > 0;
		},

		effectiveAccept() {
			if (this.accept) {
				return this.accept;
			} else {
				if (this.acceptTypes) {
					let accType = this.acceptTypes;
					let accArr = [];
					if (!Array.isArray(accType)) {
						accType = [accType];
					}
					accType.forEach(t => {
						if (/^text$/i.test(t)) accArr.push(".txt")
						if (/^images?$/i.test(t)) accArr.push("image/*")
						if (/^videos?$/i.test(t)) accArr.push("video/*")
						if (/^(audios?|music)$/i.test(t)) accArr.push("audio/*")
						if (/^pdf$/i.test(t)) accArr.push(".pdf")
						if (/^(docx|(ms)?word)$/i.test(t)) accArr.push(".docx")
						if (/^(xlsx|(ms)?excel)$/i.test(t)) accArr.push(".xlsx")
						if (/^zip$/i.test(t)) accArr.push(".zip")
						if (/^rar$/i.test(t)) accArr.push(".rar")
					})
					if (accArr.length > 0) {
						return accArr.join(",");
					}
				}
			}
			return undefined;
		},
		showSubtitle() {
			return this.mode === 'edit' && !this.isDragging;
		},
		uploadClasses() {
			return {
				'upload-block__error': this.error,
				'upload-block__dragging': this.isDragging,
			}
		}
	},
	watch: {
		files(val) {
			this.setFiles(val);
		}
	},

	mounted() {
		this.setFiles(this.files);
	},

	methods: {
		async downloadFile(doc) {

			const isSign = Array.isArray(this.files)
				? this.files.filter(f => f.documentId === doc.documentId)
					.some(f => f?.signs.length && f?.signs.some(s => s.signedAt))
				: this.files?.signs?.some(s => s.signedAt);

			try {
				if (isSign && doc.file.type === 'application/pdf') {
					await downloadWithStamp(doc);
				} else {
					let buffer;
					buffer = await getFilePublic(doc.fileId);
					saveArrayBufferToFile(buffer, doc.file.name);
				}

				this.$notify({
					type: SUCCESS,
					title: 'Документ загружен',
				});
			} catch(err) {
				console.error(err)
				this.$notify({
					type: ERROR,
					title: 'При скачивании документа что-то пошло не так',
					text: err.response?.data?.message,
				});
			}
		},
		documentViewRoute(doc) {
			return this.$router.resolve({ name: DOCUMENT_SIGN_WATCH_PAGE, query: { documentId: doc.documentId }}).href;
		},
		setFiles(val) {
			if (this.multiple) {
				if (!val || !Array.isArray(val) || Array.isArray(val) && val.length === 0) { // нет значений
					this.fileArr = [];
					return;
				}
			} else {
				if (!val || Array.isArray(val) || typeof val !== "object" || Object.keys(val).length === 0) { // пусто
					this.fileArr = [];
					return;
				}
			}
			// значение
			const effVal = this.multiple ? val : [val];
			if (this.propMapper) {
				this.fileArr = effVal.map(ff => ({ ...this.propMapper(ff), _tmpId: ff._tmpId }));
			} else {
				this.fileArr = effVal;
			}
		},

		fileCtrlClick() {
			this.fileCtrl.click()
		},
		fileCtrlClear() {
			this.fileCtrl.value = null;
		},

		eventMap(obj) {
			if (obj.file) obj.file = { name: obj.file.name, type: obj.file.type, size: obj.file.size }
			if (!this.eventMapper) return obj;
			var obj_ = this.eventMapper(obj);
			if (obj._tmpId) obj_ = { ...obj_, _tmpId: obj._tmpId };
			return obj_;
		},

		emitChange(value) {
			if (this.multiple) {
				this.$emit('changeValue', value
					.map(ff => ({ ...ff, ...this.mixData }))
					.map(ff => this.eventMap(ff))
				);
			} else {
				if (value) {
					this.$emit('changeValue', this.eventMap({ ...value[0], ...this.mixData }));
				} else {
					this.$emit('changeValue', null);
				}
			}
		},

		findInFiles(tmpId, foundHandler) {
			const idx = this.fileArr.findIndex(ff => ff._tmpId === tmpId);
			if (idx > -1) {
				foundHandler(idx);
			}
		},

		onInput(e) {
			if (this.multiple) {
				const inputArr = Array.from(e.target.files).map(ff => ({ file: ff, _tmpId: randomUuid() }));
				this.fileCtrlClear();
				inputArr.forEach(fff => this.doUpload(fff));
				this.emitChange([...this.fileArr, ...inputArr ]);
			} else {
				const inputFile = { file: e.target.files[0], _tmpId: randomUuid() };
				this.fileCtrlClear();
				this.doUpload(inputFile);
				this.emitChange([ inputFile ]);
			}
		},

		onRemove(idx) {
			if (idx < 0 || idx >= this.fileArr.length) return;
			const delFile = this.fileArr[idx];
			const files = this.fileArr.filter((_, ii) => ii !== idx);
			this.emitChange(files.length || this.multiple ? files : null);
			this.doDelete(delFile);
		},

		doUpload(file) {
			const tmpId = file._tmpId;
			if (typeof this.uploadHandler === 'function') {
				this.pendingButton = true;
				this.$emit('uploaded', false);
				this.uploadHandler(file.file)
					.then(uploadedFile => {
						this.findInFiles(tmpId, idx => {
							delete uploadedFile._tmpId;
							this.emitChange(this.fileArr.map((ff, ii) => ii === idx ? uploadedFile : ff));
							this.$emit('uploaded', uploadedFile);
						})
					}).finally(() => {
						this.pendingButton = false;
					});
			}
		},

		doDelete(fileObj) {
			if (typeof this.deleteHandler === 'function') {
				this.deleteHandler(fileObj)
					.then(deletedFile => {
						this.$emit('deleted', deletedFile)
					})
			}
		},

		dragover(event) {
			event.preventDefault();
			this.isDragging = true;
		},

		dragleave() {
			this.isDragging = false;
		},
		
		drop(event) {
			event.preventDefault();
			this.isDragging = false;
			const transferFiles = event.dataTransfer.files;
			if (this.multiple) {
				const inputArr = Array.from(transferFiles).map(ff => ({ file: ff, _tmpId: randomUuid() }));
				this.fileCtrlClear();
				inputArr.forEach(fff => this.doUpload(fff));
				this.emitChange([...this.fileArr, ...inputArr ]);
			} else {
				if (transferFiles.length > 1) {
					this.$notify({
						title: 'Можно загрузить только один файл',
						type: ERROR,
					});
					return;
				}
				const inputFile = { file: transferFiles[0], _tmpId: randomUuid() };
				this.fileCtrlClear();
				this.doUpload(inputFile);
				this.emitChange([ inputFile ]);
			}
		}
	}
}
</script>
