<template>
	<UiModal
		:show="isVisible"
		:width="width"
		:height="height"
		:isNotify="isNotify"
		:skeleton-loading="skeletonLoading"
		:skeleton-message="skeletonMessage"
		:closeByClickOutside="closeByClickOutside"
		styles="overflow:hidden;display:flex"
		@show="closeModal"
	>
		<div class="dialog-content" :class="dialogContentClass">
			<h2 class="dialog-content__header">
				<div style="width:90%"><slot name="header" v-bind="{ resolve: clickButtonHandler }">{{ title }}</slot></div>
				<button class="dialog-content__close-icon" tabindex="-1" @click="closeModal"/>
			</h2>
	
			<div 
				ref="dialogMainContent"
				:class="mainContentClasses"
			>
				<slot v-bind="{ resolve: clickButtonHandler }" />
			</div>
	
			<div 
				v-if="!withoutFooter"
				:class="{
					'dialog-content__footer': true,
					'footer-left': footerLeft,
					'footer-span': footerSpan
				}"
			>
				<slot name="footer-start"/>
				<div class="dialog-content__footer-error">
					{{ validatorMessage }}
				</div>
				<slot name="footer"/>
				<div class="dialog-content__footer-buttons">
					<UiButton
						v-for="(btn, idx) in dialogButtons"
						:key="`btn#${idx}`"
						class="dialog-content__footer-button"
						v-bind="{ type: 'secondary', ...btn }"
						@click="clickButtonHandler(`${btn.name}`)"
					/>
				</div>
			</div>
			<span v-if="footerSpan" class="dialog-content__footer-span">{{ footerSpan }}</span>
		</div>
	</UiModal>
</template>

<script>
/**
 * Диалог с заголовком и кнопками.
 * С опциональной валидацией, выводящей рядом с кнопками сообщение об ошибке.
 * События:
 * @event open - открытие диалога
 * @event close - закрытие диалога
 * 
 * Пример использования:
 * 
 * <UiDialog
 *   ref="dialog1"
 *   title="User dialog"
 *   btn-ok-cancel
 * >
 * ...
 * </UiDialog>
 * ...
 * 
 *   this.$refs.dialog1.show()
 *     .then(button => {
 *       if (button === "ok") {
 *         // save changes
 *         ...
 *       }
 *     })
 * 
 */
export default {
	name: "UiDialog",

	provide: {
		scope: 'dialog',
	},

	props: {
		/**
         * Заголовок диалога
         */
		title: {
			type: String,
			required: true,
		},
		/**
         * Ширина диалога
         */
		width: {
			type: String,
			default: "60%",
		},
		/**
		 * Высота диалога
		 */
		height: {
			type: String,
			default: "",
		},
		/**
         * Массив кнопок диалога.
         * Каждая кнопка закрывает диалог, но перед этим передается валидатору (validator), если задан.
         * Структура элементов массива:
         *   - label: string - Надпись на кнопке;
         *   - name: string - Имя (идентификатор) кнопки, передается в валидатор и в промис при закрытии;
         *   - ... - другие атрибуты UiButton.
         * }
         */
		buttons: {
			type: Array,
			default: () => [],
		},
		/**
         * Предустановленные наборы кнопок.
         * Приоритет предустановленных кнопок ниже, чем у buttons.
         */
		btnOkCancel: {
			type: Boolean,
			default: false,
		},
		btnYesNo: {
			type: Boolean,
			default: false,
		},
		btnYesNoCancel: {
			type: Boolean,
			default: false,
		},
		/**
         * Функция валидации результата ввода в диалоге.
         * Вызывается при нажатии любой кнопки перед закрытием.
         * Интерфейс функции:
         * @interface function(button: string): string | boolean
         * Возвращает true, если валидация пройдена и диалог должен закрыться.
         * Возвращает строку с сообщением об ошибке, которая будет показана пользователю, диалог не закроется.
         */
		validator: {
			type: Function,
			default: null,
		},
		/**
         * Удаляет футер с кнопками у модалки.
         */
		withoutFooter: {
			type: Boolean,
			default: false,
		},
		/**
     * Убирает кнопки в футоре
    */
		withoutButtons: {
			type: Boolean,
			default: false,
		},
		/**
         * Удаляет скролл (контент будет растягивать модалку).
         */
		withoutScroll: {
			type: Boolean,
			default: false,
		},
		/**
         * Смещает кнопки футера в левую часть модалки.
         */
		footerLeft: {
			type: Boolean,
			default: false,
		},
		/**
         * Добавляет спан-надпись под футером.
         */
		footerSpan: {
			type: String,
			default: '',
		},
		/**
         * Возможность закрыть модалку при клике вне ее.
         */
		closeByClickOutside: {
			type: Boolean,
			default: true,
		},
		/**
		 * Обновляет hash страницы при открытии/закрытии.
		 */
		hash: {
			type: String,
			default: undefined,	
		},
		isNotify: {
			type: Boolean,
			default: false,
		},
		skeletonMessage: {
			type: String,
		},
		skeletonLoading: {
			type: Boolean,
			default: false,
		},
		dialogContentClass: {},
	},

	data: function() {
		return {
			isVisible: false,
			promiseResolver: null,
			validatorMessage: "",
			prevHash: null,
			scrollbarIsVisible: false,
		}
	},

	computed: {
		dialogButtons: function () {
			if (this.withoutButtons) {
				return [];
			}
			if (this.buttons && Array.isArray(this.buttons) && this.buttons.length) {
				return this.buttons;
			}
			let buttonsArr = [];
			if (this.btnYesNo || this.btnYesNoCancel) {
				buttonsArr.push({ name: "yes", label: "Да", type: "primary" });
				buttonsArr.push({ name: "no", label: "Нет" });
			}
			if (this.btnOkCancel) {
				buttonsArr.push({ name: "ok", label: "ОК", type: "primary" });
			}
			if (this.btnOkCancel || this.btnYesNoCancel) {
				buttonsArr.push({ name: "cancel", label: "Отмена" });
			}
			if (!buttonsArr.length) {
				buttonsArr.push({ name: "close", label: "Закрыть" });
			}
			return buttonsArr;
		},
		mainContentClasses() {
			return {
				'custom-scrollbar': true,
				'dialog-content__main': true,
				'dialog-content__main--without-scroll': this.withoutScroll,
				'dialog-content__main--scrollbar-visible': this.scrollbarIsVisible,
			}
		},
	},

	watch: {
		isVisible: {
			immidiate: true,
			async handler() {
				if (!this.isVisible) {
					this.scrollbarIsVisible = false;
					return;
				}
				
				await this.$nextTick();
				await this.$nextTick(); // 2 nextTick, чтобы учесть изменения в UiDialog + Portal
				const content = this.$refs.dialogMainContent;
				this.scrollbarIsVisible = content.scrollHeight > content.clientHeight;
			},
		},
	},

	mounted() {
		if (this.hash && this.$route.hash === this.hash) {
			this.show();
		}
	},

	methods: {
		/**
		 * Метод для вызова диалога.
		 * Запускает диалог и возвращает Promise, который разрешается после закрытия диалога.
		 * В результат промиса приходит имя нажатой кнопки.
		 * @returns Промис с результатом закрытия диалога.
		 */
		show() {
			this.validatorMessage = "";
			this.isVisible = true;
			this.$emit("open");
			if (this.hash) {
				this.prevHash = this.$route.hash === this.hash ? '' : this.$route.hash;
				this.updateHash(this.hash);
			}
			return new Promise((resolve) => {
				this.promiseResolver = resolve;
			});
		},

		updateHash(hash) {
			this.$router.push({ hash, query:this.$route.query }).catch(() => {});
		},

		clickButtonHandler(btn) {
			let validatorResult = true;
			if (this.validator) {
				validatorResult = this.validator(btn);
			}
			if (validatorResult === true) {
				// валидация пройдена - закрываем диалог и разрешаем промис
				this.validatorMessage = "";
				this.isVisible = false;
				this.promiseResolver(btn);
				this.$emit("close");
				if (this.hash && this.$route.hash === this.hash) {
					this.updateHash(this.prevHash);
				}
			}
			else {
				if (validatorResult) { // сообщение
					this.validatorMessage = validatorResult;
				}
			}
		},
		closeModal() {
			if (this.isVisible) {
				this.isVisible = false;
				this.promiseResolver("close");
				this.$emit("close");
				if (this.hash && this.$route.hash === this.hash) {
					this.updateHash(this.prevHash);
				}
			} 
		},
		handleTabKey(event) {
			const focusableElements = this.$el.querySelectorAll(
				'input, button:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'
			);
			const firstElement = focusableElements[0];
			const lastElement = focusableElements[focusableElements.length - 1];

			if (event.shiftKey && document.activeElement === firstElement) {
				// Shift + Tab: если фокус на первом элементе, перейти к последнему
				lastElement.focus();
				event.preventDefault();
			} else if (!event.shiftKey && document.activeElement === lastElement) {
				// Tab: если фокус на последнем элементе, перейти к первому
				firstElement.focus();
				event.preventDefault();
			}
		},
	},
}
</script>
